import React, { useRef, useState, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import { openDB } from 'idb';

function VideoAnalysis() {
  const fileUploadRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [model, setModel] = useState(null);
  const [predictions, setPredictions] = useState(null);
  const [db, setDb] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [progress, setProgress] = useState(0); // state to track progress

  useEffect(() => {
    openDB('video-analysis', 1, {
      upgrade(db) {
        db.createObjectStore('predictions');
      },
    }).then(setDb);

    tf.loadLayersModel('https://storage.googleapis.com/tfjs-models/tfjs/mobilenet_v1_0.25_224/model.json')
      .then((loadedModel) => {
        console.log('Model loaded.');
        setModel(loadedModel);
      });
  }, []);

  useEffect(() => {
    const runAnalysis = async () => {
      if (videoRef.current.currentTime >= videoRef.current.duration) return;

      await analyzeFrame();
      if (videoRef.current && videoRef.current.currentTime < videoRef.current.duration) {
        videoRef.current.currentTime += 0.2;
        requestAnimationFrame(runAnalysis);
      }
    };

    if (videoLoaded && videoRef.current && model && db) {
      runAnalysis();
    }
  }, [videoLoaded, model, db]);

  const clearData = async () => {
    if (db) {
      const tx = db.transaction('predictions', 'readwrite');
      await tx.store.clear();
      await tx.done;
      console.log('Database cleared');
    } else {
      console.log('Database not initialized');
    }
  };

  const handleFileUpload = async (event) => {

    // Clear the database before starting a new analysis
    await clearData();

    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);
    videoRef.current.src = fileURL;
    setVideoLoaded(false);
    videoRef.current.onloadeddata = () => setVideoLoaded(true);
  };

  const analyzeFrame = async () => {
    if (!db || !model || videoRef.current.currentTime >= videoRef.current.duration) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext('2d');

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = tf.browser.fromPixels(canvas);
    const resized = tf.image.resizeBilinear(imageData, [224, 224]);
    const normalized = resized.div(tf.scalar(255.0));
    const batched = normalized.reshape([1, 224, 224, 3]);

    const prediction = await model.predict(batched).data();
    console.log('Current Time:', videoRef.current.currentTime);

    // Calculate progress
    const progressPercent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progressPercent.toFixed(0)); // show progress upto 2 decimal places

    const tx = db.transaction('predictions', 'readwrite');
    await tx.store.put(prediction, videoRef.current.currentTime);
    await tx.done;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Video Analysis</h1>

      <div className="mb-8">
        <input
          type="file"
          ref={fileUploadRef}
          accept="video/*"
          onChange={handleFileUpload}
          className="py-2 px-4 text-sm text-white bg-blue-500 hover:bg-blue-400 rounded shadow cursor-pointer"
        />
      </div>

      {/* Commented out video and canvas elements */}
      <div className="mb-4" style={{ display: 'none' }}>
        <video ref={videoRef} width="640" height="480" className="shadow-lg" />
      </div>

      {/* Kept the canvas element but added a style to hide it */}
      <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }} />

      <p className="text-lg text-gray-700">{`Progress: ${progress}%`}</p>
    </div>
  );
}

export default VideoAnalysis;
